import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { handleModal, setCartProducts } from "../../feature/productSlice";
import { Button, Form } from "react-bootstrap";
import { AiFillCloseCircle } from "react-icons/ai";
import styles from "./modal.module.scss";
import { BsFillCartCheckFill } from "react-icons/bs";
import { registerSell } from "../../api/productapi";
import { getBankAccount, getCustomersInfo } from "../../api/financeapi";
import { setBankAccountInfo } from "../../feature/userSlice";
import Select from "react-select";

const customStyles = {
  content: {
    top: "50%",
    left: "60%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

// setting up the main element
ReactModal.setAppElement("#root");
function Modal({ showToastMessage, getproductInfo }) {
  const { bankaccountinformation, userloginfo } = useSelector(
    (store) => store.userReducer
  );
  const [customer_information, setCustomerInfo] = useState([]);
  const [customerData, setcustomerData] = useState([]);
  const [customerVal, setcustomerVal] = useState("");
  const { isOpen, CartProducts } = useSelector((store) => store.productReducer);
  const [isSubmitButton, setIsSubmitButton] = useState(false);
  const [totalQuantity, setTotalQuantity] = useState("0");
  const [totalPrice, setTotalPrice] = useState("0");
  const dispatch = useDispatch();
  useEffect(() => {
    getbankAccountinfo();
  }, []);
  const getbankAccountinfo = async () => {
    var bankaccountinfo = await getBankAccount();
    var customerInfo = await getCustomersInfo();
    dispatch(setBankAccountInfo(bankaccountinfo.Message));
    setCustomerInfo(customerInfo.Message);

    var filter_customer = [];
    for (var i = 0; i < customer_information.length; i++) {
      filter_customer.push({
        value: customer_information[i].customer_id,
        label:
          customer_information[i].customer_name +
          " | " +
          customer_information[i].customer_phone,
      });
    }
    setcustomerData(filter_customer);
  };

  function closeModal() {
    dispatch(handleModal());
    dispatch(setCartProducts([]));
    setIsLiability(false);
    setIsSubmitButton(false);
    setTotalPrice("0");
    setTotalQuantity("0")
  }
  const [sellingPrice, setSellingPrice] = useState([]);
  const [productQuantity, setProductQuantity] = useState([]);
  const [isLiability, setIsLiability] = useState(false);
  // if (currentProductInfo.length === 0) return;
  if (CartProducts.length === 0) return;
  const handleOnChange = (event) => {
    const { name, value } = event.target;
    if (name === "buy_type") {
      if (value === "Liability") {
        setIsLiability(true);
        var filter_customer = [];
        for (var i = 0; i < customer_information.length; i++) {
          if (customer_information[i].customer_id != "CUS171")
            filter_customer.push({
              value: customer_information[i].customer_id,
              label:
                customer_information[i].customer_name +
                " | " +
                customer_information[i].customer_phone,
            });
        }
        setcustomerData(filter_customer);
      } else {
        setIsLiability(false);

        var filter_customer = [];
        for (var i = 0; i < customer_information.length; i++) {
          filter_customer.push({
            value: customer_information[i].customer_id,
            label:
              customer_information[i].customer_name +
              " | " +
              customer_information[i].customer_phone,
          });
        }
        setcustomerData(filter_customer);
      }
    }
  };

  const handlePriceChange = (index, value) => {
    const newNumbers = [...sellingPrice];
    newNumbers[index] = value;
    setSellingPrice(newNumbers);
    var total = newNumbers.reduce(function (acc, curr) {
      if (isNaN(curr)) {
        return acc; // Skip NaN values, don't add them to the accumulator
      }
      return acc + curr;
    }, 0);
    setTotalPrice(total);
  };
  const handleQuantityChange = (index, value) => {
    const newNumbers = [...productQuantity];
    newNumbers[index] = value;
    setProductQuantity(newNumbers);
    // console.log(productQuantity)
    var total = newNumbers.reduce(function (acc, curr) {
      if (isNaN(curr)) {
        return acc; // Skip NaN values, don't add them to the accumulator
      }
      return acc + curr;
    }, 0);
    setTotalQuantity(total);

    console.log(total);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    var message = "";
    var productData = [];
    var error_counter = 0;

    for (var i = 0; i < CartProducts.length; i++) {
      // console.log(sellingPrice[i], CartProducts[i].product_selling_price);
      if (
        parseFloat(productQuantity[i]) >
        parseFloat(CartProducts[i].current_quantity)
      ) {
        error_counter++;
        productData = [];
        message = CartProducts[i].product_name + "' Tiradaan  kuuma taalo";
        showToastMessage("error", message);
        break;
      }
      if (
        parseFloat(sellingPrice[i]) <
        parseFloat(CartProducts[i].product_buying_price) *
          parseFloat(productQuantity[i])
      ) {
        error_counter++;
        productData = [];
        message = CartProducts[i].product_name + "' Qiimahaan  lama ogolo";
        showToastMessage("error", message);
        break;
      }
      productData.push({
        product_id: CartProducts[i].product_id,
        product_quantity: productQuantity[i],
        selling_price: sellingPrice[i],
        buying_price: CartProducts[i].product_buying_price,
      });
    }

    if (error_counter <= 0) {
      setIsSubmitButton(true);
      var buytype = document.getElementById("buy_type").value;
      // var customer = document.getElementById("customer").value;
      var customer = customerVal.value;
      var post = new FormData();
      post.append("action", "registerSell");
      post.append("productData", JSON.stringify(productData));
      post.append("BuyType", buytype);
      post.append("CustomerID", customer);
      post.append("SelledBy", userloginfo.userid);
      if (buytype === "Cash") {
        var account_type = document.getElementById("account_type").value;
        post.append("AccountType", account_type);
      } else {
        post.append("AccountType", "-");
      }
      // console.log(customer);return;
      var sellinfo = await registerSell(post);
      // console.log(sellinfo);
      if (sellinfo.status) {
        closeModal();
        showToastMessage("success", sellinfo.Message);
        getproductInfo();
      } else {
        showToastMessage("error", sellinfo.Message);
      }
      setIsSubmitButton(false);
    }
  };

  return (
    <div>
      <ReactModal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={customStyles}
        className={styles.sellmodal}
        overlayClassName={styles.overlay}
      >
        <h2
          style={{
            textAlign: "center",
            margin: "3%",
            fontWeight: "bold",
            marginBottom: "2%",
          }}
        >
          SELL PRODUCT
        </h2>
        <div className={styles.sellmodal_content}>
          <Form className={styles.sellform} onSubmit={handleSubmit}>
            {CartProducts.map((cart, index) => (
              <div className="row">
                <div className="form-group col-6 mb-3">
                  <label>Product Name</label>
                  <Form.Control
                    key={index}
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    value={cart.product_name}
                    readOnly
                  />
                </div>

                <div className="form-group col-3 mb-3">
                  <label>Quantity</label>
                  <Form.Control
                    key={index}
                    type="number"
                    min="0.001"
                    step="0.001"
                    className="form-control"
                    placeholder="Quantity"
                    name="product_quantity[]"
                    id="product_quantity[]"
                    onChange={(event) =>
                      handleQuantityChange(
                        index,
                        parseFloat(event.target.value)
                      )
                    }
                    required
                  />
                </div>
                <div className="form-group col-3 mb-3">
                  <label>Selling Price</label>
                  <Form.Control
                    key={index}
                    type="number"
                    step="0.01"
                    className="form-control"
                    placeholder="Price"
                    onChange={(event) =>
                      handlePriceChange(index, parseFloat(event.target.value))
                    }
                    required
                  />
                </div>
              </div>
            ))}

            <div className="row">
              <div className="form-group col-12 mb-3">
                <label>Buying Type</label>

                <select
                  className="form-select"
                  name="buy_type"
                  id="buy_type"
                  onChange={handleOnChange}
                  required
                >
                  <option value="">Select Type</option>
                  <option value="Cash">Cash</option>
                  <option value="Liability">Liability/Deyn</option>
                </select>
              </div>
            </div>
            {!isLiability && (
              <div className="row">
                <div className="form-group col-12 mb-3">
                  <label>Choose Account</label>
                  <select
                    className="form-select"
                    name="account_type"
                    id="account_type"
                    onChange={handleOnChange}
                    required
                  >
                    <option value="">Select Account</option>
                    {bankaccountinformation.map((account, index) => (
                      <option key={index} value={account.bank_id}>
                        {account.bank_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}

            <div className="row">
              <div className="form-group col-12 mb-3">
                <label>Customer</label>
                <Select
                  value={customerVal}
                  styles={{
                    menu: (baseStyles, state) => ({
                      ...baseStyles,
                      color: "black",
                    }),
                  }}
                  name="customer"
                  id="customer"
                  options={customerData}
                  onChange={setcustomerVal}
                  required
                />

                {/* <select
                  className="form-select"
                  name="customer"
                  id="customer"
                  onChange={handleOnChange}
                  required
                >
                  <option value="">Select Customer</option>
                  {customer_information.map((customer, index) => {
                    if (isLiability) {
                      if (customer.customer_id !== "CUS171") {
                        return (
                          <option key={index} value={customer.customer_id}>
                            {customer.customer_name +
                              " | " +
                              customer.customer_phone}
                          </option>
                        );
                      }
                    } else {
                      return (
                        <option key={index} value={customer.customer_id}>
                          {customer.customer_name +
                            " | " +
                            customer.customer_phone}
                        </option>
                      );
                    }
                  })}
                </select> */}
              </div>
            </div>
            <div className="row">
              <div className="form-group col-6 mb-3">
                <label>Total Quantity</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Quantity"
                  value={totalQuantity}
                  id="totalQuantity"
                  readOnly
                  style={{
                    backgroundColor: "grey",
                    color: "black",
                    fontSize: "25px",
                    fontWeight: "bolder",
                  }}
                />
              </div>

              <div className="form-group col-6 mb-3">
                <label>Total Price</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Price"
                  value={totalPrice}
                  id="totalPrice"
                  readOnly
                  style={{
                    backgroundColor: "grey",
                    color: "black",
                    fontSize: "25px",
                    fontWeight: "bolder",
                  }}
                />
              </div>
            </div>
            <div
              className="form-group col-md-12 mt-4"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {isSubmitButton === true ? (
                <>
                  <Button type="button" className="btn btn-primary">
                    <BsFillCartCheckFill /> Loading......
                  </Button>
                  <Button
                    type="button"
                    onClick={closeModal}
                    className="btn btn-danger"
                    style={{ marginLeft: "20%" }}
                  >
                    <AiFillCloseCircle /> Close
                  </Button>
                </>
              ) : (
                <>
                  <Button type="submit" className="btn btn-primary">
                    <BsFillCartCheckFill /> Sell
                  </Button>
                  <Button
                    type="button"
                    onClick={closeModal}
                    className="btn btn-danger"
                    style={{ marginLeft: "20%" }}
                  >
                    <AiFillCloseCircle /> Close
                  </Button>
                </>
              )}
            </div>
          </Form>
        </div>
      </ReactModal>
    </div>
  );
}

export default Modal;
